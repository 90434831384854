<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="系统资源编码">
            <el-input v-model="searchForm.code" placeholder="请输入 系统资源编码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="数据包号">
            <el-input v-model="searchForm.ebdId" placeholder="请输入 数据包号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="业务类型">
            <el-select clearable v-model="searchForm.ebdType" placeholder="请选择" size="large">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

        </el-col>
        <el-col :span="6">
          <el-form-item label="起始结束时间">
            <el-date-picker
                v-model="time"
                type="datetimerange"
                value-format='yyyy-MM-dd HH:mm:ss'
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
<!--        <el-col :span="6">-->
<!--          <el-form-item label="记录结束时间">-->
<!--            <el-date-picker v-model="searchForm.endTime" value-format='yyyy-MM-dd HH:mm:ss' type="datetime" placeholder="请选择 记录结束时间"></el-date-picker>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
      </template>
<!--      <template #BTN>-->
<!--        <el-button class="searchBtn">下载tar包</el-button>-->
<!--      </template>-->
    </Searchbar>
    <basicTable
        :tableData="tableData"
        :attrList="attrList"
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :page="page.current"
        :limit="page.limit"
        :total="page.total">
    </basicTable>
  </div>
</template>

<script>
import {onMounted, reactive, toRefs} from "vue";
import { downloadTar } from "@/api/devops/control";
import { getData } from "@/api/devops/send";
import Searchbar from '@/components/Searchbar';
export default {
  components:{
    Searchbar
  },
  setup(){
    let state = reactive({
      time:[],
      options:[
        {
          value:"EBM",
          label:"应急广播消息"
        },
        {
          value:"EBI",
          label:"应急广播信息"
        },
        {
          value:"EBMStateRequest",
          label:"应急广播消息播发状态查询"
        },{
          value:"EBMStateResponse",
          label:"应急广播消息播发状态反馈"
        },
        {
          value:"EBRPSInfo",
          label:"应急广播平台信息"
        },
        {
          value:"EBRSTInfo",
          label:"为台站（前端）信息"
        },
        {
          value:"EBRASInfo",
          label:"应急广播适配器信息"
        },
        {
          value:"EBRBSInfo",
          label:"传输覆盖播出设备信息"
        },
        {
          value:"EBRDTInfo",
          label:"平台设备及终端信息"
        },
        {
          value:"EBRPSState",
          label:"应急广播平台状态"
        },
        {
          value:"EBRASState",
          label:"应急广播适配器状态"
        },
        {
          value:"EBRBSState",
          label:"传输覆盖播出设备状态"
        },
        {
          value:"EBRDTState",
          label:"平台设备及终端状态"
        },
        {
          value:"EBMBrdLog",
          label:"播出记录"
        },
        {
          value:"ConnectionCheck",
          label:"心跳"
        },
        {
          value:"EBDNotify",
          label:"处理结果通知"
        },
        {
          value:"EBDResponse",
          label:"接收回执文件"
        },
        {
          value:"OMDRequest",
          label:"运维数据请求"
        },
      ],
      attrList:[
        {
          prop: "omdDetail",
          label: "类型名称",
          render: (row) => <div>{row.omdDetail}</div>,
        },
        {
          prop: "omdType",
          label: "数据类型",
          render: (row) => <div>{row.omdType}</div>,
        },
        {
          prop: "sendDesc",
          label: "目标回执状态",
          render: (row) => <div>{row.sendDesc}</div>,
        },
        {
          prop: "sendEbdId",
          label: "包号",
          render: (row) => <div>{row.sendEbdId}</div>,
        },
        {
          prop: "targetCode",
          label: "编码",
          render: (row) => <div>{row.targetCode}</div>,
        },
        {
          prop: "sendTime",
          label: "发送时间",
          render: (row) => <div>{row.sendTime}</div>,
        },
        {
          prop: "targetName",
          label: "目标系统",
          render: (row) => <div>{row.targetName}</div>,
        },
        {
          prop: "",
          label: "下载发包",
          render: (row) => <el-button type="text" icon="Download" onClick={()=>download(row)}>
            下载
          </el-button>,
        },
      ],
      searchForm:{},
      tableData:[],
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
    })

    onMounted(()=>{
      onLoad();
    })

    function onLoad(form = {}){
      getData({ limit:state.page.limit, page:state.page.current, ...form }).then(res=>{
        state.tableData = res.data.data.list;
        state.page.total = res.data.data.count;
      })
    }

    function onSearch(){
      state.page.current = 1;
      if(state.time){
        state.searchForm.startTime = state.time[0];
        state.searchForm.endTime = state.time[1];
      }
      onLoad(state.searchForm);
    }

    function resetForm() {
      state.searchForm = {};
      state.time = [];
      onLoad(state.searchForm);
    }

    function download(row){
      downloadTar({ type:1, ebdId:row.sendEbdId }).then(res=>{
        const blob = new Blob([res.data], {
          type: "application/x-tar"
        });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "数据包.tar");
        link.click();
        link = null;
      })
    }

    function handleSizeChange(pageSize){
      state.page.limit = pageSize;
      onLoad();
    }

    function handleCurrentChange(currentPage) {
      state.page.current = currentPage;
      onLoad();
    }

    return{
      handleCurrentChange,
      handleSizeChange,
      resetForm,
      onSearch,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__content .el-date-editor {
  height: 100% !important;

  .el-range-separator {
    line-height: 40px;
  }
}
</style>